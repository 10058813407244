import React from 'react';
import './App.css';
import { BrowserRouter as Router, Link } from 'react-router-dom'
  
function About (){
    return (
    <div className = "App-container About">
        <div className="App About">
            <div className='LOGO-text About'>
                <div className='LOGO-row About'>
                    <span className="x-a small">@</span>
                    <span className="x-r small">r</span>
                    <span className="x-t small">t </span>

                    <span className="x-a2 small">!</span>
                    <span className="x-s small">$</span>
                </div>
                <div className='LOGO-row About'>
                    <span className="x-e1">e</span>
                    <span className="x-v">v</span>
                    <span className="x-e2">e</span>
                    <span className="x-r2">r</span>
                    <span className="x-y">y</span>
                    <span className="x-w">w</span>
                    <span className="x-h">h</span>
                    <span className="x-e3">e</span>
                    <span className="x-r3">r</span>
                    <span className="x-e4">e</span>
                    <span className="x-d3">.</span>
                </div>
            </div>

            <div className="about">
            Art is Everywhere is an archive of the human experience born out of a fascination with beauty and made entirely out of crowd submissions. The project redefines "gratitude" into "art" in an attempt to truly capture the significance of beauty within our lives. The hope of the project is to amplify these beautiful shared parts of our human experience and to inspire a sense of gratitude, optimism, and togetherness.
            </div>

            <div className="about">
            The website itself is an experiment in brutalist web design: including only the submissions themselves and using a timestamp instead of a date as a clue to the user's potential mindset at the time of submission. I used the infamous style of the "blue screen of death" to represent the idea that art and beauty can be found in even the most unexpected places.
            </div>
        </div>

        <div className="App-header About">
            <Link to="/" className="header-button">
                <div >
                    View the Archive
                </div>
            </Link>
            <Link to="/submit" className="header-button">
                <div >
                    Add to the Archive
                </div>
            </Link>
        </div>
    </div>
    )
}
  
export default About;