// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyCM7dLgA45LZ6teG8eXjV-IZkn5L_oVaAQ",
  authDomain: "art-is-everywhere-6256a.firebaseapp.com",
  projectId: "art-is-everywhere-6256a",
  storageBucket: "art-is-everywhere-6256a.appspot.com",
  messagingSenderId: "916386044259",
  appId: "1:916386044259:web:011408ab48644e385f5b56",
  measurementId: "G-JTS8BELE4Y"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);

export { database };
