
import './App.css';
import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom'

import { getDatabase, ref, set, get, child, onValue } from "firebase/database";
import {database as db} from './firebase.js';

function Home() {

  const [art, setArt] = useState([{title: '...', date: '', id: 0},]);

  useEffect(() => {
    const all_art_ref = ref(db, 'art');
    const fetchData = () => {
      onValue(all_art_ref, (snapshot) => {
        const data = snapshot.val();
        console.log("HOME registered snapshot")
        console.log(data)
        console.log(typeof data)
        console.log(Object.values(data))
        setArt(Object.values(data).reverse());
      });
    };

    fetchData(); // Fetch data when the component mounts

    // Clean up the event listener when the component unmounts
    return () => {
    };
  }, []); // Empty dependency array ensures the effect runs only once

  
  return (
    <div className = "App-container">
      <div className="App-header">
        <Link to="/about" className="header-button">
            <div >
                About the Project
            </div>
        </Link>
        <Link to="/submit" className="header-button">
            <div >
                Add to the Archive
            </div>
        </Link>
      </div>
      <div className="App">
        <div className='LOGO-text'>
          <div className='LOGO-row'>
            <span className="x-a">@</span>
            <span className="x-r">r</span>
            <span className="x-t">t </span>
          </div>

          <div className='LOGO-row'>
            <span className="x-a2">!</span>
            <span className="x-s">$</span>

            <span className="x-d1">.</span>
            <span className="x-d2">.</span>
            <span className="x-d3">.</span>
          </div>
        </div>
        <div className="art">
          {art.map((a) => {
            let prepended_number = a.date
          
            return (
              <div className="art-row" key={a.key}>
                <div className="art-title">{a.title}</div>
                <div className="art-date">{prepended_number}</div>
              </div>
            )
          })}   
        </div>   
      </div>
    </div>
  );
}

export default Home;
