
import './App.css';
import React, { useState, Component } from 'react';
import { BrowserRouter as Router,Routes, Route, Link } from 'react-router-dom';

import About from './About';
import Home from './Home';
import Submit from './Submit';

class App extends Component {
  render() {
      return (
      <Router>
        <div className="App">
          {/* <Home/> */}
          <Routes>
            <Route exact path='/' element={< Home />}></Route>
            <Route exact path='/about' element={< About />}></Route>
            <Route exact path='/submit' element={< Submit />}></Route>
          </Routes>
        </div>
      </Router>
  );
  }
  }
    
  export default App;