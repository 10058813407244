import React, { useState, useCallback } from 'react';
import './App.css';
import { BrowserRouter as Router, Link } from 'react-router-dom'

// import { getDatabase } from "firebase/database";
import { getDatabase, ref, set, get, child, update, push, onValue} from "firebase/database";
  
function Submit (){

    const db = getDatabase();
    const [art, setArt] = useState([]);
    const [item, setItem] = useState('');

    const dbRef = ref(db, `submissions/`);
    const readData = useCallback(() => {
        onValue(dbRef, (snapshot) => {
          const data = snapshot.val();
          const loadedList = [];
          for (const key in data) {
            loadedList.push({
            id: key,
            title: data[key].title,
            description: data[key].description,
            });
          }
          setArt(loadedList);
        });
      }, [dbRef]);
    function writeArt() {
        console.log('writeart called, item is: ' + item)
        const text = item;
        const newPostKey = push(child(ref(db), "submissions")).key;
        const dateObject = new Date();
        const hours = String(dateObject.getHours());
        const minutes = String(dateObject.getMinutes()).padStart(2, '0');
        let prepended_number = `${hours}:${minutes}`
        const new_updates = {
            key: newPostKey,
            title: text,
            date: prepended_number
        }
        const updates = {};
        updates[`/art/${newPostKey}`] = new_updates;
        console.log(new_updates)
        update(ref(db), updates);
        readData();

        window.location.href = '/';
    }


    
    return (
        <div className = "App-container About">
            <div className="App Submit">
                <div className='LOGO-text Submit'>
                    <div className='LOGO-row About'>
                        <span className="x-e1">"</span>
                        <span className="x-a small">@</span>
                        <span className="x-r small">r</span>
                        <span className="x-t small">t </span>
                        <span className="x-v quote">"</span>
                    </div>
                </div>

                <div className="submission-body">
    
                    <div className="about small">
                    (n)
                    </div>
                    <div className="about small">
                    1) Anything that makes our human experience uniquely beautiful
                    </div>
                    <div className="about small">
                    2) Anything that makes your heart and brain giddy
                    </div>
                    <div className="about small">
                    3) Anything indescribable beyond simply: "art"
                    </div>

                    <div className="submit">
                        <input 
                            className="submit-text" 
                            placeholder=""
                            onChange={(e) => setItem(e.target.value)}
                            onSubmit={() => writeArt()}
                        />
                        <div 
                            className="submit-button"
                            onClick={() => writeArt()}
                        >
                            Submit
                        </div>
                    </div>
                </div>
            </div>

            
    
            <div className="App-header About">
                <Link to="/" className="header-button">
                    <div >
                        View the Archive
                    </div>
                </Link>
                <Link to="/about" className="header-button">
                    <div >
                        About the Project
                    </div>
                </Link>
            </div>
        </div>
        )
}
  
export default Submit;